import React from 'react';

import './App.css';
import image from './logo.png';


declare var ZoomMtg

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.2.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {



  var urlString = window.location.pathname;
  var paramString = urlString.split('/');
  var firstName = "";
  var lastName = "";

  if (paramString[1] != undefined) {
    var meetingNumber = paramString[1]
  }
  if (paramString[2] != undefined) {
    var passWord = paramString[2]
  }
  if (paramString[3] != undefined) {
    var userNameValue = paramString[3]
    var userNameArr = userNameValue.split('-');
    if (userNameArr[0] != undefined) {
      firstName = userNameArr[0].charAt(0).toUpperCase() + userNameArr[0].slice(1);
    }
    if (userNameArr[1] != undefined) {
      lastName = userNameArr[1].charAt(0).toUpperCase() + userNameArr[1].slice(1);
    }
  }
  if (paramString[4] != undefined) {
    if (paramString[4] == "pt") {
      var language = "pt";
    } else {
      var language = "en";
    }
  } else {
    var language = "en";
  }

  if (language == "pt") {
    var heading = "Você irá entrar em uma reunião ao vivo";
  } else {
    var heading = "You are about to join a live meeting";
  }

  var name = firstName + " " + lastName;

  var signatureEndpoint = 'https://api.dutraforeducation.com.br/api/generate-zoom-signature'
  var apiKey = '540uv7OUTCSueC7tGvFDGg'
  var role = 0
  var leaveUrl = 'https://dutraforeducation.com.br/live-event'
  var userName = name
  var userEmail = ''
  var registrantToken = ''

  function getSignature(e) {
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
      .then(response => {
        startMeeting(response)
      }).catch(error => {
        console.error(error)
      })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App app_background" >
      <main>
        <img src={image} alt="Dutra Education" style={{ maxWidth: '300px' }} />

        <h1> {heading}</h1>

        <button onClick={getSignature} className='blink_me'>Join Meeting</button>
      </main>
    </div >
  );
}

export default App;
